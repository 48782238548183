<template>
  <b-overlay
      :show="users === null"
      rounded="sm"
  >

  <div v-if="users !== null">

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refreshData"
    />

    <!-- Table Container Card -->
    <b-card
      class="mb-0"
    >
      <div class="my-2">
        <!-- Table Top -->
        <b-row>

<!--          <b-col-->
<!--              cols="12"-->
<!--              md="2"-->
<!--              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--          >-->
<!--            <span> تعداد کاربران : </span>-->
<!--            <span class="mx-1" >{{totalCounts}}</span>-->
<!--            <span >نفر</span>-->
<!--          </b-col>-->

          <!-- Search -->
          <b-col
              cols="12"
              md="10"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search ..."
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Count</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

<!--          <b-col-->
<!--              cols="12"-->
<!--              md="3"-->
<!--              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--          >-->
<!--            <label>نمایش</label>-->
<!--            <v-select-->
<!--                v-model="perState"-->
<!--                :options="perStateOptions"-->
<!--                :reduce="val => val.value"-->
<!--                :clearable="false"-->
<!--                class="per-page-selector d-inline-block mx-50 w-50"-->
<!--            />-->
<!--            <label>وضعیت</label>-->
<!--          </b-col>-->
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        responsive
        :fields="myTableColumns"
        primary-key="id"
        show-empty
        bordered
        striped
        empty-text="Nothing found !"
      >

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
                            <b-avatar
                              size="45"
                              :src="baseURL+data.item.selfieFileData"
                              :variant="`success`"
                              :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                            />
            </template>
                        <b-link
                          :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                          class="font-weight-bold d-block text-nowrap"
                        >
                          {{ data.item.userName }}
                        </b-link>
            <small class="text-muted">@{{ data.item.userId }}</small>
          </b-media>
        </template>

        <!-- Column: type -->
        <template #cell(type)="data">
          <b-badge
              pill
              class="text-capitalize"
              :variant="``"
          >
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: edit -->
        <template #cell(edit)="data">
          <b-link :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }">
            <feather-icon icon="EditIcon" size="20"/>
          </b-link>
        </template>

<!--        &lt;!&ndash; Column: chat &ndash;&gt;-->
<!--        <template #cell(chat)="data">-->
<!--          <b-link :to="{ name: 'apps-chats-id', params: { id: data.item.userId } }">-->
<!--            <feather-icon icon="MessageCircleIcon" size="20"/>-->
<!--          </b-link>-->
<!--        </template>-->


<!--        &lt;!&ndash; Column: factors &ndash;&gt;-->
<!--        <template #cell(factors)="data">-->
<!--          <b-link :to="{ name: 'apps-users-factors', params: { id: data.item.userId } }">-->
<!--            <feather-icon icon="ListIcon" />-->
<!--          </b-link>-->
<!--        </template>-->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
<!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center "
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserListAddNew from './UserListAddNew.vue'
import {UsersGetAllRequest} from "@/libs/Api/Users";
import Helper from "@/libs/Helper";

export default {
  title:" پنل ادمین",
  data(){
    return{
      users:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      perState:'',
      baseURL:Helper.baseUrl,
      perStateOptions:[
        {label:'همه',value:''},
        {label:'وارد شده',value:1},
        {label:'فالو نکرده',value:2},
        {label:'آیدی اشتباه',value:3}
      ],
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'name' ,label :'User'},
        { key: 'userName' ,label :'User Name'},
        { key: 'email' ,label :'email'},
        // { key: 'mobile',label: 'موبایل'},
        { key: 'edit',label:'Edit'},
        // { key: 'chat',label:'چت'},
        // { key: 'factors',label:'فاکتورهای کاربر'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getUsers(this.perPage,this.currentPage,this.searchQuery,this.perState)
  },
  methods:{
    refreshData(){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery,this.perState)
    },
    async getUsers(count,pageNumber,search,state){
      let _this = this;
      let data = {
        pageNumber:pageNumber,
        count:count,
        search:search,
        state:state
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this);
      usersGetAllRequest.setParams(data);
      await usersGetAllRequest.fetch(function (content){
        _this.users = content.users;
        _this.totalCounts = content.usersCount;
      },function (error){
        console.log(error);
      })
    }
  },
  watch:{
    perPage:function (nv,ov){
      this.getUsers(nv,this.currentPage,this.searchQuery,this.perState);
    },
    currentPage:function (nv,ov) {
      this.getUsers(this.perPage,nv,this.searchQuery,this.perState);
    },
    searchQuery:function (nv,ov){
        this.getUsers(this.perPage,this.currentPage,nv,this.perState);
    },
    perState:function (nv,ov){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery,nv);
    }
  },
  components: {
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BInputGroup, BInputGroupAppend,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
